import React from "react";
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import Question from "./question";
import Response from "./response";
import HelpBubble from "./helpbubble";
import IntroHints from '../../lesson/introhints';
import NoAccess from "../noAccess";

import scrollQuestionsDiv from '../../../functions/scrollquestionsdiv';


function Content(props) {

  // Define variables
  let learnerHasAccessToLesson = useSelector((state) => state.lesson.learnerHasAccessToLesson);

  // Always allow access to the tutorial lesson
  if ( window.location.pathname.includes('lessons/167') ) {
    learnerHasAccessToLesson = true;
  }

  const [showHelpBubble, setShowHelpBubble] = useState(false);
  const [introHints, setIntroHints] = useState(null);

  const key = 'content-' + props.content.module_content_iterator;
  const divClass = props.content.delay ? 'is-hidden' : '';

  const translateToggle = props.translateToggle;
  const translateContent = props.translateContent;
  const contentObejct = props.content;
  const type = contentObejct.type;
  const questionId = contentObejct.questionId;
  const responseText = props.response;
  const responseBlobUrl = props.responseBlobUrl;
  const suggestedResponses = contentObejct.suggestedResponses;
  const retryLimit = props.retryLimit;
  const retryCount = props.retryCount;
  const audioSpeed = props.audioSpeed;
  const allowRepeat = props.allowRepeat;


  // Page load actions
  useEffect(() => {

    if( props.content.delay ) {

      const element = document.getElementById(key);
      // const loadingElement = document.getElementById(this.state.key + '-loading');

      setTimeout(() => {

        element.classList.remove('is-hidden');
        // loadingElement.classList.add('is-hidden');

        scrollQuestionsDiv();

        // Remove the dealy from the object so it does not try to keep delaying on re-renders
        props.content.delay = null;


      }, props.content.delay);

    }

    // If this is the tutorial lesson, show hints
    const shownIntroHintOnTutorial = localStorage.getItem('shownIntroHintOnTutorial');
    if( shownIntroHintOnTutorial === null && props.response && props.content.questionId === 1 && props.isActiveQuestion ) {
        setIntroHints(<IntroHints />);
        localStorage.setItem('shownIntroHintOnTutorial', true);
    }

  // }, []);
  });


  // Define functions
  function loadHelpBubble() {
    setShowHelpBubble(true);
  }

  function closeHelpBubble() {
    setShowHelpBubble(false);
  }


  // Screen components
  let response;
  if( type === 'question' ) {
    if( learnerHasAccessToLesson ) {
      response =
        <Response
          tryAgain={props.tryAgain}
          questionId={questionId}
          responseText={responseText}
          responseBlobUrl={responseBlobUrl}
          responseObject={props.responseObject}
          suggestedResponses={suggestedResponses}
          loadHelpBubble={loadHelpBubble}
          handleTranscript={props.handleTranscript}
          retryLimit={retryLimit}
          retryCount={retryCount}
          lastMessage={contentObejct.content}
          allowRepeat={allowRepeat}
        />
    } else {
      response = <NoAccess />;
    }

  }

  let helpBubble;
  if( showHelpBubble ) {
    helpBubble =
      <HelpBubble
        title={'Example Responses'}
        helpContents={suggestedResponses}
        closeBubble={closeHelpBubble}
        type={'example-responses'}
      />
  }


  return (
    <div>
      {introHints}
      <div key={key} id={key} class={divClass} >
        <Question
          content={contentObejct}
          translateToggle={translateToggle}
          translateContent={translateContent}
          audioSpeed={audioSpeed}
        />
        {response}
        {helpBubble}
      </div>
    </div>
  );
}

export default Content;