import { useState } from 'react';
import { useDispatch } from "react-redux";

import '../../../styles/record.css';
import alertMessage from '../../utilities/alertMessage';
import { reportError } from '../../../functions/sentry';
import scoreAssessment from '../../../functions/scoreAssessment';

import RecordUiBig from '../../utilities/recordUiBig';
import RecordTimer from './recordTimer';

import lessonSlice from '../../../store/lesson';

let mediaRecorder;


function AssessmentRecord(props) {

  // Dispatch
  const dispatch = useDispatch();

  function setActiveRecording(status) {
    // dispatch({ type: 'setActiveRecording', payload: status});
    dispatch(lessonSlice.actions.setActiveRecording(status));
  }

  // Define variables
  const [recordStatus, setRecordStatus] = useState('waiting'); // waiting, listening, processing, scoring, complete
  const [stopEnabled, setStopEnabled] = useState(false);
  const handleTranscript = props.handleTranscript;
  const providedText = props.providedText;
  const scoreType = props.scoreType;
  const messages = props.messages;
  const seconds = props.seconds;
  const extras = props.extras;


  // Define functions
  function handleClick() {
    switch (recordStatus) {
      case 'listening':
        if( stopEnabled ) {
          stopRecording();
          break;
        }
      case 'processing':

        break;
      case 'scoring':

        break;
      case 'complete':

        break;
      default:
        // waiting
        initalizeStream();
        break;
    }
  }

  function initalizeStream() {
    navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    .then(initalizeRecorder)
    .then(startRecording)
    .catch((error) => {
      reportError(error);
      alertMessage('Sorry, we cannot access your microphone. Please allow microphone access so you can record your response.', error);
    });
  }

  const initalizeRecorder = function(stream) {

    const options = {
      type: 'audio',
      mimeType: 'audio/wav',
      numberOfAudioChannels: 1,
      audioBitsPerSecond: 128000,
      bufferSize: 128
    }

    let recordedChunks = [];
    mediaRecorder = new MediaRecorder(stream, options);

    // When the recording starts
    mediaRecorder.addEventListener('start', function() {
      setRecordStatus('listening');
    });

    // Push data into the recordedChunks Array
    mediaRecorder.addEventListener('dataavailable', function(e) {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    });

    mediaRecorder.addEventListener('stop', function() {
      setRecordStatus('processing');

      // Close the recording
      if (mediaRecorder) {
        stream.getAudioTracks().forEach(track => {
          track.stop();
        });
      }

      const audioData = recordedChunks;

      // Convert saved chunks to blob
      const blob = new Blob(audioData, {type: 'audio/wav'});

      // Generate audio url from blob
      const audioUrl = window.URL.createObjectURL(blob);

      const payload = {
        scoreType: scoreType,
        providedText: providedText,
        audioBlob: blob,
        messages: messages,
        extras: extras
      }

      scoreAssessment(payload).then((response) => {

        setRecordStatus('complete');
        // clearInterval(interval);

        if( response.success ) {

          // Add the text
          if( providedText ) { response.data.providedText = providedText }

          // Add the blob
          response.data.blob = blob;

          // Add the audio url
          response.data.audioUrl = audioUrl;

          handleTranscript(response.data);
        } else {
          if( response.error === 'No speech recognized' ) {
            setRecordStatus('waiting');
            alertMessage('Sorry, we had a problem hearing you. Can you please try again?', 'error');
          } else {
            setRecordStatus('waiting');
            reportError(response.error);
            alertMessage('Sorry, there was an error, please try again.', 'error');
          }
        };

      }).catch((error) => {
        console.log('Other Transcription Error: ' + error);
        reportError(error);
      });

    });

    // Clear the recording
    // It's ok to go ahead and clear because we will want to clear no matter
    // the outcome of the translation funciton
    recordedChunks = [];
  }

  function startRecording() {
    setActiveRecording(true);
    mediaRecorder.start();
    setTimeout(() => setStopEnabled(true), 400);
  }


  function stopRecording() {
    mediaRecorder.stop();
    setActiveRecording(false);
    setStopEnabled(false);
  }

  function handleTimeout() {
    if( recordStatus === 'listening' ) {
      stopRecording();
    } else {
      handleTranscript({success: false, error: 1001});
    }
  }


  return (
    <div>
      <div>
        <RecordUiBig
          handleClick={handleClick}
          recordStatus={recordStatus}
        />
      </div>

      <RecordTimer
        seconds={seconds}
        handleTimeout={handleTimeout}
        recordStatus={recordStatus}
      />

    </div>
  );
}

export default AssessmentRecord;