// import React from "react";
import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import Content from "./content";
import lessonSlice from '../../../store/lesson';


function ModuleContent(props) {

  // Define variables
  const dispatch = useDispatch();
  const [contentsArray, setContentsArray] = useState([]);
  const currentLessonId = useSelector((state) => state.lesson.currentLessonId);

  const contents = props.contents;
  const response = props.response;
  const responseBlobUrl = props.responseBlobUrl;
  const questionsLength = props.questionsLength;
  const thisQuestionIndex = props.thisQuestionIndex;
  const translateToggle = props.translateToggle;
  const translateContent = props.translateContent;
  const handleTranscript = props.handleTranscript;
  const responseObject = props.responseObject;
  const tryAgain = props.tryAgain;
  const collectAudio = props.collectAudio;
  const retryLimit = props.retryLimit;
  const retryCount = props.retryCount;
  const audioSpeed = props.audioSpeed;
  const allowRepeat = props.allowRepeat;

  const isActiveQuestion = (questionsLength - 1) === thisQuestionIndex;
  const key = Date.now();


  // Component load actions
  useEffect(() => {

    // Try to play the audio
    const audioUrlArray = [];
    contents.map((content, index) => {
      if( content.audioUrl ) {
        audioUrlArray.push(content.audioUrl);
      }
    });

    collectAudio(audioUrlArray);


    // Reset the array when we start
    // Otherwise it keeps old data
    setContentsArray([]);
    delayQuestionsT = 0;

    const tempContents = structuredClone(contents);

    tempContents.forEach((content, index) => {
      let delay = null;

      const iterator = content.module_content_iterator;

      if( iterator > delayQuestionsT ) {

        delay = 700 * (index + 1);

        // Update the redux store
        // dispatch({type: 'setDelayQuestions', payload: { delayQuestions: iterator }});
        dispatch(lessonSlice.actions.setDelayQuestions({delayQuestions: iterator}));

        delayQuestionsT = iterator;
      }

      // Add the delay to the content object itself
      content.delay = delay;
      addContentToContents(content);

    });

  // }, [props.contents]);
  }, [currentLessonId]);


  const addContentToContents = (content) => {
    setContentsArray(contentsArray => [...contentsArray, content]);
  };


  return (
    <div key={key}>
      {contentsArray.map((content, index) => (
        <Content
          key={index}
          content={content}
          response={response}
          responseBlobUrl={responseBlobUrl}
          responseObject={responseObject}
          tryAgain={tryAgain}
          isActiveQuestion={isActiveQuestion}
          handleTranscript={handleTranscript}
          translateToggle={translateToggle}
          translateContent={translateContent ? translateContent.find(t => t.moduleContentId === content.id) : null}
          retryLimit={retryLimit}
          retryCount={retryCount}
          audioSpeed={audioSpeed}
          allowRepeat={allowRepeat}
        />
      ))}
    </div>
  );
};

let delayQuestionsT = 0;

export default ModuleContent;