import axios from 'axios';
import { reportError } from "./sentry";

// transcribeFunction(blob, audioUrl, providedText, messages, extras)
// const transcribe = async (audioBlob, audioFileUri) => {
const transcribe = async (audioBlob, audioFileUri, fillerOne, fillerTwo, extras) => {

  let apiUrlBase = "https://microservice.flowspeak.io/";
  const apiUrlString = "api/v2/transcribe_and_score_complex";



  // // -------------------------------------
  // // For testing and staging
  // // -------------------------------------
  // const host = window.location.host;
  // const onStaging = host.search('--flow-web.netlify.app') > 0;
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || onStaging) {

  //   // apiUrlBase = "https://nvl-api-microservice-staging.herokuapp.com/";
  //   apiUrlBase = "http://localhost:8080/";

  //   // let testing = true;
  //   // testing = false; // Change this to toggle real testing

  //   // if( testing ) {
  //   //   const dummyData = generateDummyData(audioFileUri);
  //   //   return dummyData;
  //   // }
  // }
  // // -------------------------------------


  const apiUrl = `${apiUrlBase}${apiUrlString}`;
  const url = encodeURI(apiUrl);

  const formData = new FormData();
  const apiKey = "fs000000000003044092320002333";

  formData.append('file', audioBlob, 'audio.wav');

  if( extras?.lastMessage ) {
    formData.append('lastMessage', extras.lastMessage);
  }

  if( extras?.allowRepeat ) {
    formData.append('allowRepeat', extras.allowRepeat);
  }


  const config = {
    method: 'post',
    url: url,
    headers: {
      authorization: `Bearer ${apiKey}`,
      "Content-Type": "multipart/form-data",
     },
    data : formData,
  };

  return axios(config).then(function (response) {

    if( response?.data?.success ) {

      const responseData = response.data;
      responseData.audioFileUri = audioFileUri;

      return responseData;

    } else {

      let errorMessage = 'Sorry, there was an error.';
      if( response?.data?.error === 'No speech is detected' || response?.data === 'No speech is detected' ) {
        errorMessage = 'No speech recognized';
      } else if( response?.data.error === 'isRepeat' ) {
        errorMessage = response.data.error;
      }

      return { success: false, error: errorMessage}
    }
  }).catch(function (error) {
    reportError(error);
    return { success: false, error: error}
  });

}

export default transcribe;





function generateDummyData(audioFileUri) {

  const transcript = generateRandomSentence();
  const overallPercent = generateRandomScore();
  const cadencePercent = generateRandomScore();
  const pronunciationPercent = generateRandomScore();
  const vocabularyPercent = generateRandomScore();
  const fluencyPercent = generateRandomScore();
  const grammarPercent  = generateRandomScore();

  const ieltsOverallPercent = generateRandomIeltsScore();
  const ieltsFluencyPercent = generateRandomIeltsScore();
  const ieltsGrammarPercent = generateRandomIeltsScore();
  const ieltsPronunciationPercent = generateRandomIeltsScore();
  const ieltsVocabularyPercent = generateRandomIeltsScore();

  const transcriptWordScore = [];
  transcript.split(' ').map((word, index) => {
    const number = Math.floor(Math.random() * 90) + 20
    transcriptWordScore.push(
      {
        qualityScore: number,
        qualityScoreClass: setScoreWord(number),
        word: word,
        phone_score_list: [
          {
            phone: 't',
            quality_score: 70,
            sound_most_like: 'n',
          },
          {
            phone: 'eh',
            quality_score: 100,
            sound_most_like: 'eh',
          },
          {
            phone: 's',
            quality_score: 98.53,
            sound_most_like: 's',
          },
          {
            phone: 't',
            quality_score: 40
          }
        ]
      },
    )
  });

  const responseData = {
    success: true,
    transcribed: true,
    transcript: transcript,
    rawTranscript: transcript,
    audioFileUri: audioFileUri,
    wordCount: 10,
    overall: {
      word: setScoreWord(overallPercent),
      percent: overallPercent
    },
    cadence: {
      word: setScoreWord(cadencePercent),
      percent: cadencePercent,
      speechRate: 3
    },
    pronunciation: {
      word: setScoreWord(pronunciationPercent),
      percent: pronunciationPercent
    },
    vocabulary: {
      word: setScoreWord(vocabularyPercent),
      percent: vocabularyPercent
    },
    fluency: {
      word: setScoreWord(fluencyPercent),
      percent: fluencyPercent
    },
    grammar: {
      word: setScoreWord(grammarPercent),
      percent: grammarPercent
    },
    ieltsScores: {
      overall: ieltsOverallPercent,
      fluencyAndCoherence: ieltsFluencyPercent,
      grammar: ieltsGrammarPercent,
      pronunciation: ieltsPronunciationPercent,
      vocabulary: ieltsVocabularyPercent,
      wordCountMessage: "( too few words )"
    },
    wordScore: transcriptWordScore
  }

  return responseData;
}



// Functions for test data ---
function setScoreWord(percent) {
  let word;

  if( percent >= 80 ) {
    word = 'Excellent';
  } else if( percent <= 79 && percent >= 56 ) {
    word = 'Good';
  } else {
    word = 'Ok';
  }

  return word;
}


function generateRandomSentence() {
  const arrayOne = ['This is a', 'I am doing well', 'Good to know', 'Down there'];
  const arrayTwo = ['blue', 'red', 'green', 'turquoise', 'velvet', 'chartreuse' ];
  const arrayThree = ['door', 'vessel', 'moon', 'cloud', 'sea'];

  const one = arrayOne[Math.floor(Math.random() * arrayOne.length)];
  const two = arrayTwo[Math.floor(Math.random() * arrayTwo.length)];
  const three = arrayThree[Math.floor(Math.random() * arrayThree.length)];

  const four = arrayOne[Math.floor(Math.random() * arrayOne.length)];
  const five = arrayTwo[Math.floor(Math.random() * arrayTwo.length)];
  const six = arrayThree[Math.floor(Math.random() * arrayThree.length)];

  const string = one + ' ' + two + ' ' + three + '. ' + four + ' ' + five + ' ' + six;
  return string + ' this are bad grammar, plese fix me. thiere is one vase.'
}

function generateRandomScore() {
  return Math.floor(Math.random() * (100 - 15 + 1) + 15);
}

function generateRandomIeltsScore() {
  return (Math.floor(Math.random() * (9 - 1 + 1) + 1)).toFixed(1);
}