
import axios from "axios";
import { getToken } from "./tokenHelpers";

let apiBase = "https://api.flowspeak.io/api/";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  apiBase = "http://localhost:3001/api/";
  // apiBase = "https://flow-server-staging-419687e67428.herokuapp.com/api"; // Staging URL
} else {

  // const host = window.location.host;
  // const onStaging = host.search('--flow-web.netlify.app') > 0;
  // // const onStaging = false;

  // if( onStaging ) {
  //   // apiBase = "https://flow-server-staging-419687e67428.herokuapp.com/api"; // Staging URL
  //   apiBase = "https://api.flowspeak.io/api/";
  // }

}

export const apiBaseUrl = apiBase;


axios.defaults.baseURL = apiBaseUrl;

const setAuthHeader = () => ({
  headers: { authorization: `Bearer ${getToken()}` }
});



export const lessonCatalog = () => axios.get(`/series/lesson_catalog`);

export const assessmentStart = (payload) => axios.post(`/assessments/start`, payload);
export const assessmentUpdate = (payload) => axios.post(`/assessments/update_assessment`, payload);
export const assessmentNextContent = (payload) => axios.post(`/assessments/next_content`, payload);
export const assessmentSaveResponses = (payload) => axios.post(`/assessments/save_responses`, payload);
export const assessmentGenerateScore = (payload) => axios.post(`/assessments/generate_score`, payload);
export const assessmentEmailReport = (payload) => axios.post(`/assessments/email_report`, payload);

export const signup = payload => axios.post(`/users/`, payload);
export const signin = payload => axios.post(`/users/login`, payload);
export const forgotpassword = payload => axios.post(`/users/send_password_reset_email`, payload);
export const magicLink = payload => axios.post(`/users/send_magic_link`, payload);
export const resetpassword = payload => axios.post(`/users/reset_password`, payload);
export const setPassword = payload => axios.post(`/users/set_password`, payload);

export const sendSmsLoginCode = (payload) => axios.post(`/users/send_sms_login_code`, payload);
export const validateSmsLoginCode = (payload) => axios.post(`/users/validate_sms_login_code`, payload);

export const sendSmsAccountVerifyCode = (payload) => axios.post(`/users/send_sms_account_verify_code`, payload);
export const validateSmsAccountVerifyCode = (payload) => axios.post(`/users/validate_sms_account_verify_code`, payload);

export const sendSmsAddPhoneCode = (payload) => axios.post(`/users/send_sms_add_phone_code`, payload, setAuthHeader());
export const validateSmsAddPhoneCode = (payload) => axios.post(`/users/validate_sms_add_phone_code`, payload, setAuthHeader());

export const resendVerifyCode = (payload) => axios.post(`/users/resend_verify_code`, payload);

export const getTopicsList = () => axios.get(`/users/topics_list`, setAuthHeader());

export const updateLearnerProfile = payload => axios.post(`/user`, payload, setAuthHeader());
export const updatePersonalInformation = payload => axios.post(`/user/update_personal_information`, payload, setAuthHeader());
export const rateLearningModule = payload => axios.post(`/learning_modules/rate_learning_module`, payload, setAuthHeader());
export const updateLMU = payload => axios.post(`/lmu_update`, payload, setAuthHeader());
export const createComment = payload => axios.post(`/comments`, payload, setAuthHeader());
export const flagComment = payload => axios.post('/flag_comment', payload, setAuthHeader());
export const updateSavedLearningModules = payload => axios.post('/users/update_saved_learning_modules', payload, setAuthHeader());
export const validateAuthCode = payload => axios.post('/users/validate_auth_code', payload, setAuthHeader());
// export const setShareSettings = payload => axios.post('/users/set_share_settings', payload, setAuthHeader()); # TO DO - delete file where this is used, clean up route on server

export const getProfile = () => axios.get(`/user/profile`, setAuthHeader());
// export const getThisWeek = () => axios.get(`/user/this_week`, setAuthHeader());
export const getNotifications = () => axios.get(`/user/notifications_feed`, setAuthHeader());
// export const getAllLessons = () => axios.get(`/learning_modules`, setAuthHeader());
export const getAllSeries = () => axios.get(`/series`, setAuthHeader());
// export const getSavedLessons = () => axios.get(`/learning_modules?saved=true`, setAuthHeader());
export const getSavedLessons = () => axios.get(`/users/saved_lessons`, setAuthHeader());
export const getDashboardData = () => axios.get(`/dashboard_data`, setAuthHeader());

export const createBlock = payload => axios.post(`/blocks`, payload, setAuthHeader());
export const updateBlock = payload => axios.put(`/blocks/999`, payload, setAuthHeader()); // We don't actually have the block id, so pass in a dummy id
export const retakeBlock = payload => axios.post(`/blocks/${payload.id}/retake`, payload, setAuthHeader());
export const blockUpdateName = payload => axios.post(`/blocks/${payload.blockId}/update_name`, payload, setAuthHeader());

export const getPosts = () => axios.get(`/posts`, setAuthHeader());
export const getPost = (id) => axios.get(`/posts/${id}`, setAuthHeader());
export const createPost = payload => axios.post(`/posts`, payload, setAuthHeader());
export const likePost = payload => axios.post(`/like_post`, payload, setAuthHeader());

export const getResponses = (id) => axios.get(`/module_contents/${id}`, setAuthHeader());
export const getResponseComments = (id) => axios.get(`/responses/${id}`, setAuthHeader());
export const getResponse = (id) => axios.get(`/responses/show_response/${id}`, setAuthHeader());

export const getChallengeList = () => axios.get(`/user/challenge_list`, setAuthHeader());

export const getPreferenceData = () => axios.get(`/users/get_preference_data`, setAuthHeader());
export const updatePreferenceData = (payload) => axios.post(`/users/update_preference_data`, payload, setAuthHeader());

// export const getDailyLesson = () => axios.get(`/user/daily_lesson`, setAuthHeader());
// export const getWeeklyProgress = () => axios.get(`/user/weekly_progress`, setAuthHeader());
export const getInterests = () => axios.get(`/interests`, setAuthHeader());
export const getIndustries = () => axios.get(`/industries`, setAuthHeader());
export const getLanguageLevels = () => axios.get(`/language_levels`, setAuthHeader());
export const getUser = userId => axios.get(`/users/${userId}`, setAuthHeader());
export const getStripePortalUrl = () => axios.get(`/users/generate_stripe_portal_url`, setAuthHeader());
export const getSavedPromoCode = () => axios.get(`/user/get_saved_promo_code`, setAuthHeader());
export const getUpdatedScore = () => axios.get(`/user/get_updated_score`, setAuthHeader());
export const getPublicProfile = (id) => axios.get(`/user/public_profile/${id}`, setAuthHeader());
export const applyPromoCode = (payload) => axios.post(`/users/apply_promo_code`, payload, setAuthHeader());
export const getMoreLessons = () => axios.get(`/user/load_more_lessons`, setAuthHeader());
export const getMoreBonusLessons = (payload) => axios.post(`/user/load_more_bonus_lessons`, payload, setAuthHeader());
export const cancelAccount = (payload) => axios.post('/users/cancel_account', payload, setAuthHeader());

export const getPublicProfileUnauthed = (payload) => axios.get(`/user/public_profile_unauthed/${payload.id}?badge_id=${payload.badgeId}&certificate_id=${payload.certificateId}`);

export const getCourses = () => axios.get(`/courses`, setAuthHeader());
export const startCourse = payload => axios.post(`/start_course`, payload, setAuthHeader());
export const cancelCourse = payload => axios.post(`/cancel_course`, payload, setAuthHeader());
export const updateCourse = payload => axios.put(`/courses/999`, payload, setAuthHeader()); // We don't actually have the course learner profile id, so pass in a dummy id

export const getBite = (id) => axios.get(`/bites/${id}`, setAuthHeader());

export const getListeningLesson = (id) => axios.get(`/listening_lessons/${id}`, setAuthHeader());

export const getExpression = (id) => axios.get(`/expressions/${id}`, setAuthHeader());
export const updateSavedExpressions = payload => axios.post(`/expressions/${payload.id}/save`, payload, setAuthHeader());

export const getAiTalk = (id) => axios.get(`/ai_talks/${id}`, setAuthHeader());
export const updateSavedAiTalks = payload => axios.post(`/ai_talks/${payload.id}/save`, payload, setAuthHeader());

export const getBooster = (id) => axios.get(`/boosters/${id}`, setAuthHeader());
export const updateSavedBoosters = payload => axios.post(`/boosters/${payload.id}/save`, payload, setAuthHeader());

export const getExercise = (id) => axios.get(`/exercises/${id}`, setAuthHeader());
export const logExerciseStart = (payload) => axios.post(`/exercises/log_start`, payload, setAuthHeader());
// export const logExerciseComplete = (payload) => axios.post(`/exercises/log_complete`, payload, setAuthHeader());
export const logExerciseComplete = (payload) => axios.post(`/exercises/log_exercise_complete`, payload, setAuthHeader());
export const updateShareSetting = (payload) => axios.post(`/exercises/${payload.id}/update_share_setting`, payload, setAuthHeader());

export const getOrgGroup = (slug) => axios.get(`/organizations/org_group_signup_data/${slug}`);
export const orgGroupCreateLearner = payload => axios.post(`/organizations/org_group_create_learner`, payload);

export const getOrganizationBySlug = payload => axios.post(`/organizations/find_by_slug`, payload, setAuthHeader());


export const checkLearnerHasPremiumSubscription = () => axios.get(`/subscriptions/learner_has_premium_subscription`, setAuthHeader());
export const makePaymentIntent = payload => axios.post(`/subscriptions/payment_intent`, payload, setAuthHeader());
export const finalizeSubscriptionSuccess = payload => axios.post(`/subscriptions/finalize_subscription_success`, payload, setAuthHeader());
export const checkPromoCode = payload => axios.post(`/subscriptions/check_promo_code`, payload, setAuthHeader());
export const lookupPackage = payload => axios.post(`/subscriptions/lookup_package`, payload, setAuthHeader());
export const lookupPaymentDetails = payload => axios.post(`/subscriptions/lookup_payment_details`, payload, setAuthHeader());
// export const lookupCertPaymentDetails = payload => axios.post(`/subscriptions/lookup_cert_payment_details`, payload, setAuthHeader());

export const getAllTimeStats = (public_profile_id) => axios.get(`/stats/all_time/${public_profile_id}`, setAuthHeader());
export const getActivityMonitor = (public_profile_id) => axios.get(`/stats/activity_monitor/${public_profile_id}`, setAuthHeader());
export const getScoreOverTime = (public_profile_id) => axios.get(`/stats/score_over_time/${public_profile_id}`, setAuthHeader());
export const getScoreScatter = (public_profile_id) => axios.get(`/stats/score_scatter/${public_profile_id}`, setAuthHeader());
export const getLessonsCompleted = (public_profile_id) => axios.get(`/stats/lessons_completed/${public_profile_id}`, setAuthHeader());
export const getLessons = (public_profile_id) => axios.get(`/stats/lessons/${public_profile_id}`, setAuthHeader());

export const validateNativeLanguage = () => axios.get(`/translate/validate_native_langauge`, setAuthHeader());
export const translateMessage = payload => axios.post(`/translate/message`, payload, setAuthHeader());
export const translateLearningModule = payload => axios.post(`/translate/learning_module`, payload, setAuthHeader());
export const translateExpression = payload => axios.post(`/translate/expression`, payload, setAuthHeader());
export const translateBite = payload => axios.post(`/translate/bite`, payload, setAuthHeader());
export const translateListening = payload => axios.post(`/translate/listening`, payload, setAuthHeader());
export const translateBooster = payload => axios.post(`/translate/booster`, payload, setAuthHeader());


export const getAllResponses = () => axios.get(`/responses`, setAuthHeader());