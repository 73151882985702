import { useState, useEffect } from "react";
import ModuleContent from "./modulecontent";
import AudioSpeedSwitcher from "./audioSpeedSwitcher";


function LearningModule(props) {

  // Define variables
  const [audioSpeed, setAudioSpeed] = useState(null);
  const questionsLength = props.questions.length;
  const questions = props.questions;
  const translateToggle = props.translateToggle;
  const translateContent = props.translateContent;
  const nextQuestion = props.nextQuestion;
  const tryAgain = props.tryAgain;
  const handleTranscript = props.handleTranscript;
  const collectAudio = props.collectAudio;
  const retryLimit = props.retryLimit;
  const retryCount = props.retryCount;
  const allowRepeat = props.allowRepeat;


  // Component load actions
  useEffect(() => {
    setTimeout(() => {
      setAudioSpeed('normal');
    }, 2500);
  }, []);


  // Screen components
  let buttonText = 'Next';
  if( props.isLastQuestion ) {
    buttonText = 'Finish Lesson'
  }

  let nextButton;
  if( props.showNextButton ) {
    nextButton = (
      <button
        type="button"
        class='clean-button next-question-button'
        title='Tap to continue to the next question'
        onClick={nextQuestion}>{buttonText}
      </button>
    );
  }

  let audioSpeedElement;
  if( audioSpeed ) {
    audioSpeedElement = (
      <AudioSpeedSwitcher
        audioSpeed={audioSpeed}
        setAudioSpeed={setAudioSpeed}
      />
    );
  }


  return (
    <div class='questions'>
      {questions.map((question, index) => (
        <ModuleContent
          key={index}
          contents={question.contents}
          response={question.response}
          responseObject={question.responseObject}
          responseBlobUrl={question.responseBlobUrl}
          tryAgain={tryAgain}
          questionsLength={questionsLength}
          thisQuestionIndex={index}
          handleTranscript={handleTranscript}
          translateToggle={translateToggle}
          translateContent={translateContent}
          collectAudio={collectAudio}
          retryLimit={retryLimit}
          retryCount={retryCount}
          audioSpeed={audioSpeed}
          allowRepeat={allowRepeat}
        />
      ))}
      <div class='next-question-wrapper'>{nextButton}</div>
      {audioSpeedElement}
    </div>
  );
};

export default LearningModule;