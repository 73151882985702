import React from 'react';
import { useSelector } from "react-redux";
import PlayAudioIcon from "../playaudioicon";
import RecordResponse from "../recordresponse";
import ScoreDetails from "./scoredetails"


function Response(props) {

  // Define variables
  const currentLessonComplete = useSelector((state) => state.lesson.complete);
  const activeQuestions = useSelector((state) => state.lesson.activeQuestions);
  const activeQuestionCount = (activeQuestions.length - 1);
  const responseObject= props.responseObject;
  const suggestedResponses = props.suggestedResponses;
  const tryAgain = props.tryAgain;
  const loadHelpBubble = props.loadHelpBubble;
  const retryLimit = props.retryLimit;
  const retryCount = props.retryCount;

  const lastMessage = props.lastMessage;
  const allowRepeat = props.allowRepeat;

  const questionId = (props.questionId - 1);
  const responseText = (props.responseText) ? props.responseText : <RecordResponse handleTranscript={props.handleTranscript} lastMessage={lastMessage} extras={{allowRepeat: allowRepeat}} />;
  const responseBlobUrl = (props.responseBlobUrl) ? props.responseBlobUrl : null;
  const audioIcon = (responseBlobUrl) ? <div class="media-left"><PlayAudioIcon audioUrl={responseBlobUrl} responseAudio={true} /></div> : null;


  // Screen components
  let tryAgainElement = <div class='try-again-wrapper'></div>;
  let seeSuggestions;

  if( suggestedResponses ) {
    seeSuggestions = (
      <button
      type='button'
      class='clean-button see-suggestions'
      onClick={loadHelpBubble}
      title='Tap to see ideas for responses'
    >See examples</button>
    );
  }

  if( responseObject && (retryCount < retryLimit) ) {
    tryAgainElement = (
      <div class='try-again-wrapper'>
        <button
          class='clean-button try-again'
          data-key={questionId}
          onClick={tryAgain}
          title='Tap to retry your response'
        >
          Tap to retry
        </button>
      </div>
    );
  }


  // Set the card active state
  let cardActiveClass = 'response-wrapper inactive';

  if( activeQuestionCount === questionId ) {

    if( responseObject ) {
      cardActiveClass = 'response-wrapper active in-review';
    } else {
      cardActiveClass = 'response-wrapper active';
    }

  } else if( (activeQuestionCount - 1) === questionId ) {
    cardActiveClass = 'response-wrapper previous';
  }

  // If the lesson is complete, set to inactive
  if( currentLessonComplete ) {
    cardActiveClass = 'response-wrapper inactive';
  }


  let scoreDetailsHelper;
  const scoreDetailsHelperCount = localStorage.getItem('scoreDetailsHelperCount');
  if( activeQuestionCount === 1 && responseObject?.success && questionId === 1 && scoreDetailsHelperCount < 5 ) {
    scoreDetailsHelper = (
      <div class='score-helper-hint-container'>
        <span class='icon icon-arrow-double-up'></span>
        <p>Click for details</p>
      </div>
    );
  }


  return (
    <div class={cardActiveClass} id={"question-card-" + questionId}>
      <div class='response-content'>
        <div class="card-content">
          <div class="media">
          {audioIcon}
            <div class="media-content">
              <div class="has-text-right response-input">
                {responseText}
              </div>
            </div>
          </div>
        </div>
        <div class='response-extras'>
          {tryAgainElement}
          {seeSuggestions}
          <ScoreDetails
            responseObject={responseObject}
            questionId={questionId}
          />
          {scoreDetailsHelper}
        </div>
      </div>
    </div>
  );
};


export default Response;